<template>
  <div class="card-custom" v-if="ready">
    <div class="row" v-if="$store.api.user.role === 1">
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Sinistre à traiter par MVP</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.total_mvp}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Sinistre à traiter par Teletech</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.total_teletech}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Sinistre à indemniser</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.a_payer_status}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Montant sinistre à indemniser</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.a_payer_montant}}€</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 offset-md-4 mt-3">
        <div class="card border-left-primary h-100 py-2">
          <div class="card-body">
            <b-row>
              <b-col md="6">
                <b-form-group label="Date de debut">
                  <b-form-input type="date" v-model="date_start"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date de fin">
                  <b-form-input type="date" v-model="date_end"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="$store.api.user.role === 2">
      <div class="col-md-4">
        <div class="card h-100 py-2">
          <div class="card-body">
            <div class="row no-gutters text-center">
              <div class="col mr-2">
                <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">Sinistre à traiter par Teletech</div>
                <div class="h5 mb-0 font-weight-bold text-danger">{{params.meta.total_teletech}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 offset-md-4">
        <div class="card border-left-primary h-100 py-2">
          <div class="card-body">
            <b-row>
              <b-col md="6">
                <b-form-group label="Date de debut">
                  <b-form-input type="date"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date de fin">
                  <b-form-input type="date"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <b-card class="mt-3">
      <b-row class="text-center">
        <b-col md="4">
          <b-form-group label="Reference du sinistre">
            <b-form-input v-model.number="filters['id']" type="number"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Type de sinistre">
            <treeselect v-model="filters['type_id']" :multiple="true" :options="params.SinistreType"/>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Statut du sinistre">
            <treeselect v-model="filters['status_id']" :multiple="true" :options="params.SinistreStatus"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-center mt-3">
        <b-col md="3" offset-md="4">
          <b-button  v-show="loaderSearch === false" variant="primary" @click="datatableInit()">
            Recherche
          </b-button>
          <b-button  v-show="loaderSearch === true" variant="primary">
            <i class="fas fa-spinner fa-spin"></i>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" :title="'Liste des sinistres ('+datatable.data.length+')'">
      <b-table ref="datalist" stacked="md" striped hover :items="datatable.data" :fields="fields"  @row-clicked="openRow" @sort-changed="sortChanged" show-empty empty-text="Il n'y a aucun enregistrement à afficher">
        <template #cell(id)="data">
          <a :href="'/#/admin/sinistre/'+data.value">{{data.value}}</a>
        </template>
        <template #cell(date_create)="data">
          {{$store.api.timestampToDate(data.value, false)}}
        </template>
        <template #cell(type_id)="data">
          {{$store.api.getParam(params.SinistreType, data.value).titre}}
        </template>
        <template #cell(status_id)="data">
          <b-button size="sm" :variant="$store.api.getParam(params.SinistreStatus, data.value).color" class="text-white">{{$store.api.getParam(params.SinistreStatus, data.value).titre}}</b-button>
        </template>
        <template #cell(action)="data">
          <b-button size="sm" variant="indigo" class="text-white" :href="'/#/admin/sinistre/'+data.item.id" style="background: #6610f2;"><i class="fas fa-eye"></i></b-button>
        </template>
      </b-table>
      <b-pagination align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-card>
  </div>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  name: "",
  components: {
    Treeselect
  },
  data: () => ({
    ready: false,
    loaderSearch:false,
    params:{
      SinistreType:[],
      SinistreStatus:[],
      meta: {
        "total_teletech": null,
        "total_mvp": null,
        "a_payer_status": null,
        "a_payer_montant": null
      }
    },
    fields: [
      {
        key: 'id',
        label: 'Ref.',
        sortable:true,
      },
      {
        key: 'date_create',
        label: "Date déclaration",
        sortable:false,
      },
      {
        key: 'lead.nom',
        label: "Nom",
        sortable:false,
      },
      {
        key: 'lead.prenom',
        label: "Prenom",
        sortable:false,
      },
      {
        key: 'lead.email',
        label: "Mail",
        sortable:false,
      },
      {
        key: 'lead.telephone',
        label: "Telephone",
        sortable:false,
      },
      {
        key: 'type_id',
        label: "Type sinistre",
        sortable:false,
      },
      {
        key: 'status_id',
        label: "Statut sinistre",
        sortable:false,
      },
      {
        key: 'action',
        label: "Action",
        sortable:false,
      }
    ],
    datatable:{
      currentPage: 1,
      limitPage: 20,
      totalRow: 0,
      data: [],
      meta:{
        total_teletech:0
      }
    },
    sort:{
      key: 'id',
      value: 'DESC'
    },
    filters:{
      "id":null,
      "status_id":null,
      "type_id":null,
      "min_date_create":null,
      "max_date_create":null
    }
  }),
  computed:{
    date_start:{
      get(){
        var val = this.filters['min_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['min_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 00:00:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['min_date_create'] = Math.floor(gmt);
      }
    },
    date_end:{
      get(){
        var val = this.filters['max_date_create'];
        if(val === null){
          return null;
        }
        val = this.$store.api.timestampToDate(val,false)
        var tmp = val.split('-');
        if(tmp.length === 3){
          return tmp.reverse();
        }
        return null;
      },
      set(val){
        if(val === null){
          this.filters['max_date_create'] = null;
          return false;
        }
        var local = new Date(val+' 23:59:00');
        var gmt = (local.getTime() + local.getTimezoneOffset()*-60*1000)/1000;
        this.filters['max_date_create'] = Math.floor(gmt);
      }
    }
  },
  methods: {
    datatableInit(){
      if(this.loaderSearch === true){
        return false;
      }
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.loaderSearch = true;
      this.$store.api.ajax('/admin/sinistres', params, (res) => {
        this.datatable = res;
        this.loaderSearch = false;
      });
    },
    sortChanged(e){
      this.sort = {
        field: e.sortBy,
        value: e.sortDesc === true ? "DESC" : "ASC"
      };
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    openRow(i) {
      console.log(i);
    },
  },
  beforeMount() {
    this.$store.api.ajax('/admin/params?type=sinistre', null, (res) => {
      if(res.status === true){
        res.data.SinistreType.forEach((item) => {
          item.label = item.titre;
        });
        res.data.SinistreStatus.forEach((item) => {
          item.label = item.titre;
        });
        this.params = res.data
        this.ready = true;
      }
    })
  },
  mounted() {
    this.datatableInit();
  }
}
</script>
<style>
</style>
